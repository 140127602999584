import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import index from './pages/index';
import newindex from './pages/newindex';
import Premium from './pages/Premium';
import Privacy from './pages/Privacy';
import faq from './pages/faq';
import Stats from './pages/stats';
import TOS from './pages/TOS';
import Partners from './pages/Partners';
import MetadataAPI from './components/og/MetadataAPI';
// import BioMetadataAPI from './components/og/BioMetadataAPI';

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                
                <Route path="/privacy" Component={Privacy} />
                <Route path="/" Component={newindex} />
                <Route path="/api/og" element={<MetadataAPI />} />
                <Route path="/stats" Component={Stats} />
                <Route path="/tos" Component={TOS} />
                {/* <Route path="/" Component={index} /> */}
                {/* <Route path="/faq" Component={faq} /> */}
                {/* <Route path="/premium" Component={Premium} /> */}
                {/* <Route path="/api/view/bio/og" element={<BioMetadataAPI />} /> */}
            </Routes>
        </Router>
    );
};

export default App;
