// src/components/Metadata.tsx
import React from 'react';
import { Helmet } from 'react-helmet';

interface MetadataProps {
  title: string;
  description: string;
  image: string;
  url?: string;
}

const Metadata: React.FC<MetadataProps> = ({ title, description, image, url }) => (
  <Helmet>
    <title>{title}</title>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    {url && <meta property="og:url" content={url} />}
  </Helmet>
);

export default Metadata;
