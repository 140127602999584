import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const MetadataAPI: React.FC = () => {
  const [metadata, setMetadata] = useState<{
    title: string;
    description: string;
    image: string;
    url?: string;
  } | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const image = queryParams.get('image') || '';
    const description = queryParams.get('description') || 'No description';
    const title = queryParams.get('title') || 'No title';
    const url = queryParams.get('url') || '';

    const finalUrl = url && !url.startsWith('https://') ? `https://${url}` : url;

    if (finalUrl) {
      setMetadata({
        title,
        description,
        image,
        url: finalUrl || '',
      });
    } else {
    setMetadata({
        title,
        description,
        image,
        url: '',
    });
    }
  }, []);

  return metadata ? (
    <>
      <Helmet>
        <title>og</title>
        <meta property="og:title" content={metadata.title} />
        <meta property="og:description" content={metadata.description} />
        {metadata.image && <meta property="og:image" content={metadata.image} />}
        {metadata.url && <meta property="og:url" content={metadata.url} />}
      </Helmet>

      {metadata.image && metadata.image !== '' && (
        <img src={metadata.image} alt={metadata.description} />
      )}
    </>
  ) : null;
};

export default MetadataAPI;
