import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfigData } from '../utils/config';
import ThemeChangerModal from './ThemeChangerModal';

interface NavbarProps {
  theme: { mode: 'dark' | 'light'; color: string };
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  handleThemeChange: (newTheme: any) => void;
}

const Navbar: React.FC<NavbarProps> = ({ theme, isModalOpen, setIsModalOpen, handleThemeChange }) => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY < lastScrollY) {
        setIsVisible(true);
      } else if (currentScrollY > 100) {
        setIsVisible(false);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={`nav-container ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="nav-logo-container">
        <a href={ConfigData.general.home}>
          <img src={ConfigData.general.avatar} alt="xalerium.lol" className="logo" />
          <span className="nav-text" style={{ fontFamily: 'Unbounded, sans-serif' }}>{ConfigData.general.namelol}</span>
        </a>
      </div>
      <button className="mobile-menu-button" onClick={toggleMobileMenu}>
        &#9776;
      </button>
      <div className={`nav-buttons ${isMobileMenuOpen ? 'open' : ''}`}>
        <a href={ConfigData.general.premium} className="nav-button" style={{ fontFamily: 'Unbounded, sans-serif' }}>{ConfigData.general.premiumtext}</a>
        <a href={ConfigData.general.discord} className="nav-button" style={{ fontFamily: 'Unbounded, sans-serif' }}>{ConfigData.general.discordtext}</a>
        <a href={ConfigData.general.dashboard} className="nav-button-getStarted" style={{ fontFamily: 'Unbounded, sans-serif' }}>{ConfigData.general.dashboardtext}</a>
      </div>

      <style>{`

.nav-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0,0,0,.5);
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.107);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.885);
  backdrop-filter: blur(20px);
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  opacity: 0;
  animation: fadeInNav 1.5s ease-in-out forwards;
  flex-wrap: wrap;
}
@keyframes fadeInNav {
  from { opacity: 0; transform: translateX(-50%) translateY(-20px); }
  to { opacity: 1; transform: translateX(-50%) translateY(0); }
}
.nav-logo-container a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}
.logo {
  width: 30px;
  height: 30px;
  margin-right: 2px;
  padding: 5px;
}
.nav-text {
  font-size: 15px;
}
.nav-buttons {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  transition: max-height 0.3s ease;
  overflow: hidden;
}
.nav-buttons.open {
  display: block;
}
.nav-button {
  font-size: 14px;
  padding: 8px 10px;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}
.nav-button:last-child {
  margin-right: 160px;
}
.nav-button:hover {
  color: #c6c6c6;
}
.nav-button-getStarted {
  font-size: 14px;
  padding: 8px 16px;
  color: #fff;
  text-decoration: none;
  background: hsla(0,0%,100%,.05);
  border-radius: 15px;
  border: 1px solid hsla(0,0%,100%,.1);
}
.nav-button-getStarted:hover {
  background: hsla(0,0%,100%,.1);
}
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-buttons {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px; 
    left: 0;
    background-color: rgba(3, 3, 3, 0.63);
    z-index: 999;
    border-radius: 15px;
  }
  .nav-buttons.open {
    display: flex;
  }
  .mobile-menu-button {
    display: block;
  }
}

      `}</style>
    </nav>
  );
};

export default Navbar;
