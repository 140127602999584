import React from 'react';
import { ConfigData } from '../../utils/config';

const GoodBye: React.FC = () => {
    return (
        <div className="mx-12 my-10">
            <p style={{ color: '#fff' }}>
                <b>Dear users,</b>
            </p>
            <p className="mt-4" style={{ color: '#fff' }}>
                Unfortunately, due to lack of time, and motivation, we have decided to discontinue the project.
                <br />
                <br />
                If you looking for a new bio-link service(s):{' '}
                <a style={{ color: '#007aff', textDecoration: 'underline' }} href="https://e-z.bio" target="_blank" rel="noopener noreferrer">
                    <br />
                    e-z.bio
                    </a>
                    <br />
                    <a style={{ color: '#007aff', textDecoration: 'underline' }} href="https://slat.cc" target="_blank" rel="noopener noreferrer">
                    slat.cc
                </a>
                <br />
                <br />
                <b>Everyone, thank you for supporting us!</b>
            </p>
            <img
                draggable="false"
                className="max-w-5 mt-10"
                height={64}
                width={64}
                alt="xalerium.lol"
                src={ConfigData.general.avatar}
            />
        </div>
    );
};

export default GoodBye;
