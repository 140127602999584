import React, { useState } from 'react';
import Layoutnofooter from './Layoutnofooter';
import { Theme } from '../types/themes';
import { ConfigData } from '../utils/config';
import Metadata from '../components/og/Metadata';

const TOS: React.FC = () => {
    const [theme, setTheme] = useState<Theme>({ mode: 'dark', color: '#9d52ff' });
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Layoutnofooter theme={theme} setTheme={setTheme} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
            <Metadata title="xalerium.lol - Terms of Service" description="xalerium.lol high quality, biolinks, and image hosting." image={ConfigData.general.avatar} />
            <div className="tos-container">
            <h1>{ConfigData.tos.title}</h1>
            <ul>
                <li><h2>{ConfigData.tos.policy[0].title}</h2></li>
                <p>{ConfigData.tos.policy[0].description}</p>

                <li><h2>{ConfigData.tos.policy[1].title}</h2></li>
                <p>{ConfigData.tos.policy[1].description}</p>

                <li><h2>{ConfigData.tos.policy[2].title}</h2></li>
                <p>{ConfigData.tos.policy[2].description}</p>

                <li><h2>{ConfigData.tos.policy[3].title}</h2></li>
                <p>{ConfigData.tos.policy[3].description}</p>

                <li><h2>{ConfigData.tos.policy[4].title}</h2></li>
                <p>{ConfigData.tos.policy[4].description}</p>

                <li><h2>{ConfigData.tos.policy[5].title}</h2></li>
                <p>{ConfigData.tos.policy[5].description}</p>

                <li><h2>{ConfigData.tos.policy[6].title}</h2></li>
                <p>{ConfigData.tos.policy[6].description}</p>

                <li><h2>{ConfigData.tos.policy[7].title}</h2></li>
                <p>{ConfigData.tos.policy[7].description} <strong><a href={ConfigData.general.emailurl}>{ConfigData.general.email}</a></strong>.</p>
                </ul>
            </div>
            <style>
                {`
                
            .tos-container {
                max-width: 800px;
                margin: 0 auto;
                padding: 20px;
                background-color: #0d0d0d;
                border-radius: 12px;
                box-shadow: 0 4px 16px rgba(0, 0, 0, 0.8);
                color: #fff;
            }

            .tos-container h1 {
                font-size: 2rem;
                color: #fff;
                text-align: center;
                margin-bottom: 20px;
            }

            .tos-container ul {
                list-style-type: none;
                padding: 0;
            }

            .tos-container li {
                font-weight: bold;
                color: var(--accent-color);
                margin-top: 20px;
            }

            .tos-container li a {
                text-decoration: none;
                color: var(--accent-color);
            }

            .tos-container p {
                margin-top: 5px;
                color: #ccc;
            }

            .team-card, footer {
                background-color: ${theme.mode === 'dark' ? '#000' : '#f0f0f0'};
                margin-top: 40px;
            }

            .tos-container a {
                color: var(--accent-color);
            }

                `}
            </style>
        </Layoutnofooter>
    );
};

export default TOS;