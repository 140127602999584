import React from 'react';

interface ThemeChangerProps {
    currentTheme: { mode: string; color: string };
    onThemeChange: (theme: any) => void;
    onClose: () => void;
}

const ThemeChangerModal: React.FC<ThemeChangerProps> = ({ currentTheme, onThemeChange, onClose }) => {
    const colors = ['#9d52ff', '#cc2e96', '#fd2828', '#0074d9', '#ff851b'];

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Change Theme</h2>
                <div className="color-options">
                    {colors.map((color) => (
                        <button
                            key={color}
                            style={{ backgroundColor: color }}
                            onClick={() => onThemeChange({ ...currentTheme, color })}
                            className={currentTheme.color === color ? 'active' : ''}
                        />
                    ))}
                </div>
                <button className="close-btn" onClick={onClose}>
                    Close
                </button>
            </div>
            <style>{`
                .modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }
                .modal-content {
                    color: ${currentTheme.mode === 'dark' ? '#fff' : '#000'};
                    padding: 20px;
                    border-radius: 12px;
                    background-color: ${currentTheme.mode === 'dark' ? 'rgb(10, 10, 10)' : '#fff'};
                    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
                    text-align: center;
                    width: 23%;
                    height: 26%;
                }
                .mode-btn {
                    background-color: ${currentTheme.mode === 'dark' ? '#555' : '#ddd'};
                    color: ${currentTheme.mode === 'dark' ? '#fff' : '#000'};
                    padding: 10px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    border: none;
                    border-radius: 8px;
                }
                .color-options {
                    display: flex;
                    gap: 10px;
                    justify-content: center;
                    margin-bottom: 20px;
                }
                .color-options button {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    cursor: pointer;
                }
                .color-options button.active {
                    border: 3px solid ${currentTheme.mode === 'dark' ? '#fff' : '#000'};
                }
                .close-btn {
                    background-color: #ff4136;
                    color: #fff;
                    padding: 12px;
                    border: none;
                    border-radius: 8px;
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};

export default ThemeChangerModal;
