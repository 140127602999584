import React, { useEffect, useState } from 'react';
import { ConfigData } from '../utils/config';
import Links from '../utils/urls';

const StatsAPI: React.FC = () => {
    const [bioStats, setBioStats] = useState<any>(null);
    const [imageHostStats, setImageHostStats] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    const fetchBioStats = async () => {
        try {
            const response = await fetch('https://api.xalerium.hu/view/biolink/stats.php');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setBioStats(data);
        } catch (error) {
            console.error("Error fetching bio stats:", error);
            setBioStats({ total_views: 0, total_biolinks: 0, total_users: 0, total_premium_users: 0 });
        }
    };

    const fetchImageHostStats = async () => {
        try {
            const response = await fetch('https://api.xalerium.hu/view/imghost/stats.php');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setImageHostStats(data);
        } catch (error) {
            console.error("Error fetching image host stats:", error);
            setImageHostStats({ total_uploads: 0, total_domains: 0, total_storage_used: 0, total_storage_size_unit: 0, total_shortened_urls: 0 });
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchBioStats(), fetchImageHostStats()]);
            setLoading(false);
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <>
            <div className="x-grids px-5 py-3">
                <p className="x-stats-title">{ConfigData.homepage.imgstats.uploads}</p>
                <p className="x-stats-value">...</p>
            </div>
            <div className="x-grids px-5 py-3">
                <p className="x-stats-title">{ConfigData.homepage.imgstats.domains}</p>
                <p className="x-stats-value">...</p>
            </div>
            <div className="x-grids px-5 py-3">
                <p className="x-stats-title">{ConfigData.homepage.imgstats.storage}</p>
                <p className="x-stats-value">...</p>
            </div>
            <div className="x-grids px-5 py-3">
                <p className="x-stats-title">{ConfigData.homepage.imgstats.shortened}</p>
                <p className="x-stats-value">...</p>
            </div>
            <div className="x-grids px-5 py-3">
                <p className="x-stats-title">{ConfigData.homepage.biostats.views}</p>
                <p className="x-stats-value">...</p>
            </div>
            <div className="x-grids px-5 py-3">
                <p className="x-stats-title">{ConfigData.homepage.biostats.biolinks}</p>
                <p className="x-stats-value">...</p>
            </div>
        </>
        );
    }
    

    return (
        <>
        <div className="x-grids px-5 py-3">
            <p className="x-stats-title">{ConfigData.homepage.imgstats.uploads}</p>
            <p className="x-stats-value">{imageHostStats?.total_uploads || 0}</p>
        </div>
        <div className="x-grids px-5 py-3">
            <p className="x-stats-title">{ConfigData.homepage.imgstats.domains}</p>
            <p className="x-stats-value">{imageHostStats?.total_domains || 0}</p>
        </div>
        <div className="x-grids px-5 py-3">
            <p className="x-stats-title">{ConfigData.homepage.imgstats.storage}</p>
            <p className="x-stats-value">{imageHostStats?.total_storage_used + imageHostStats?.total_storage_size_unit || 0}</p>
        </div>
        <div className="x-grids px-5 py-3">
            <p className="x-stats-title">{ConfigData.homepage.imgstats.shortened}</p>
            <p className="x-stats-value">{imageHostStats?.total_shortened_urls || 0}</p>
        </div>
        <div className="x-grids px-5 py-3">
            <p className="x-stats-title">{ConfigData.homepage.biostats.views}</p>
            <p className="x-stats-value">{bioStats?.total_views || 0}</p>
        </div>
        <div className="x-grids px-5 py-3">
            <p className="x-stats-title">{ConfigData.homepage.biostats.biolinks}</p>
            <p className="x-stats-value">{bioStats?.total_biolinks || 0}</p>
        </div>
    </>
    );
};

export default StatsAPI;
