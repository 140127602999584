import React, { useEffect, useState } from 'react';
import Metadata from '../components/og/Metadata';
import StatsAPI from '../components/StatsAPI';
import Navbar from '../components/nNavbar';
import { ConfigData } from '../utils/config';

const Stats: React.FC = () => {
    return (
        <div>
        <div>
          <div className="grid place-items-center h-screen p-5">
            <div className="w-full max-w-xl">
              <div className="glow-fs"></div>
              <div className="flex justify-between mb-3">
                <img className="text-center x-logo" src="xyl.lol%20-%20Home_files/fav_lgamx3.png" alt="" />
              </div>
              <div className="mt-4">
                <div className="shadow" id="headlessui-tabs-panel-:Rdem:" role="tabpanel" data-headlessui-state="selected" aria-labelledby="headlessui-tabs-tab-:Rbem:">
                  <div className="x-panel-container p-4 pt-10">
                    <h1 className="x-header text-center">{ConfigData.homepage.statnumb}</h1>
                    <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-4 text-left x-stats mt-9">
                      <StatsAPI />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Stats;
