import React, { useEffect, useState } from 'react';
import Metadata from '../components/og/Metadata';
import Navbar from '../components/nNavbar';
import GoodBye from '../components/index/goodbye';
import { ConfigData } from '../utils/config';

const Main: React.FC = () => {
    return (
        <div>
        <div>
          <div className="grid place-items-center h-screen p-5">
            <div className="w-full max-w-xl">
              <div className="mt-4">
                <div className="shadow" id="headlessui-tabs-panel-:Rdem:" role="tabpanel" data-headlessui-state="selected" aria-labelledby="headlessui-tabs-tab-:Rbem:">
                  <div className="x-panel-container p-4 pt-10">
                    <GoodBye />
                    <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-4 text-left x-stats mt-9">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Main;
